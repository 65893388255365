<template>
  <div class="CollaborateurProfileEdition" >

    <CModal
      :show.sync="profileEdited"
      :no-close-on-backdrop="true"
      title="Mise à jour de votre profil"
      size="lg"
      color="dark"
    >
      Votre profil a bien été mis à jour 👍
      <template #footer>
        <CButton @click="profileEdited = false" color="dark">Fermer</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isProfileLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>
    
    <CRow>
      <CCol>
        <h1>Vos informations </h1>
      </CCol>
    </CRow>
    
    <CCard class="mt-3">
      <CCardBody>
        <CForm v-on:submit.prevent="updateCollaborateur()">

          <CRow>
            <CCol sm="4">
              <CSelect
                :value.sync="sex"
                @input="$v.sex.$touch()"
                :isValid="$v.sex.$dirty ? !$v.sex.$error : null"
                size="lg"
                :options='sexArrayForm'
                custom
              />
            </CCol>
            <CCol sm="4">
              <CInput
                v-model="firstName" type="text"
                maxlength="30"
                size="lg"
                placeholder="Prénom"
                @input="$v.firstName.$touch()"
                :isValid="$v.firstName.$dirty ? !$v.firstName.$error : null"
                invalid-feedback="Votre prénom doit comporter entre 2 et 30 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="4">
              <CInput
                v-model="lastName" type="text"
                maxlength="150"
                size="lg"
                placeholder="Nom"
                @input="$v.lastName.$touch()"
                :isValid="$v.lastName.$dirty ? !$v.lastName.$error : null"
                invalid-feedback="Votre nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow>
            <CCol lg="6">
              <label> <strong class="text-dark">Date de naissance</strong> </label>
              <CRow>
                <CCol sm="3">
                  <CSelect
                    :value.sync="day"
                    :options='daysArrayForm'
                    size="lg"
                    custom
                  />
                </CCol>
                <CCol sm="6">
                  <CSelect
                    :value.sync="month"
                    :options='monthsArrayForm'
                    size="lg"
                    custom
                  />
                </CCol>
                <CCol sm="3">
                  <CSelect
                    :value.sync="year"
                    :options='yearsArrayForm'
                    size="lg"
                    custom
                  />
                </CCol>
              </CRow>
              <small class="text-danger" v-if="$v.birthdateFormat.$invalid ">Veuillez saisir une date valide</small>

            </CCol>
            <CCol lg="6">
              <div role="group" class="form-group">
                <label for="profileEditionVuePhoneNumber" class>
                  Numéro de téléphone
                </label>
                <vue-phone-number-input
                  id="profileEditionVuePhoneNumber"
                  name="profileEditionVuePhoneNumber"
                  v-model="phoneNumber"
                  :default-country-code='phoneNumberResult.countryCode'
                  :preferred-countries="['FR', 'BE', 'DE']"
                  valid-color='#28a745'
                  error-color='#dc3545'
                  :translations="{
                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone',
                    example: 'Exemple :'
                  }"
                  @update='phoneNumberResult = $event'
                  :required='false'
                />
                <small class="text-danger" v-if="phoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
              </div>
            </CCol>
          </CRow>

          <CRow class="mt-2">
            <CCol class="text-center">
              <CButton
                type="submit" :color="updateCollaborateurButtonStyle"
                shape="pill" block class="px-4"
                :disabled="$v.sex.$invalid || $v.firstName.$invalid || $v.lastName.$invalid || $v.birthdateFormat.$invalid || $v.phoneNumberResult.$invalid || updateCollaborateurInProcess">
                <CSpinner size="sm" label="update profile spinner" v-if="updateCollaborateurInProcess"></CSpinner> {{ updateCollaborateurButtonText }}</CButton>
            </CCol>
          </CRow>

        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, integer } from 'vuelidate/lib/validators'
import { isDate } from '@/validators/validators'
import { APIUserConnected } from '@/api/APIUserConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnectedService = new APIUserConnected()

export default {
  name: 'CollaborateurProfileEdition',
  components: {
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      profileEdited: false,
      isProfileLoading: false,

      sex: 'H',
      firstName: '',
      lastName: '',
      year: '1975',
      month: '01',
      day: '01',
      phoneNumber: null,
      phoneNumberResult: {
        countryCode: "FR",
        isValid: true
      },

      sexArrayForm: [
        { label: 'Monsieur', value: 'H' },
        { label: 'Madame', value: 'F' },
      ],
      updateCollaborateurButtonText: 'Mettre à jour votre profil',
      updateCollaborateurButtonStyle: 'outline-primary',
      updateCollaborateurInProcess: false,
      
      monthsArrayForm: [
        { label: 'Janvier', value: '01' },
        { label: 'Février', value: '02' },
        { label: 'Mars', value: '03' },
        { label: 'Avril', value: '04' },
        { label: 'Mai', value: '05' },
        { label: 'Juin', value: '06' },
        { label: 'Juillet', value: '07' },
        { label: 'Août', value: '08' },
        { label: 'Septembre', value: '09' },
        { label: 'Octobre', value: '10' },
        { label: 'Novembre', value: '11' },
        { label: 'Décembre', value: '12' },
      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    // ---- Date 
    daysArrayForm () {
      var days = []
      for (var i=1; i<=31; i++) {
        var value = String(i)
        if (i <= 9) {
          value = "0" + String(i)
        }
        days.push({'text': value, 'value': value})
      }
      return days
    },
    
    yearsArrayForm () {
      var years = []
      for (var i=1945; i<=2010; i++) {
        var value = String(i)
        years.push({'text': value, 'value': value})
      }
      return years
    },
    
    birthdateFormat () {
      return this.year + '-' + this.month + '-' + this.day
    }
  },
  validations: {
    sex: {
      required
    },
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    birthdateFormat: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    phoneNumberResult: {
      countryCode: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          return value
        }
      },
      nationalNumber: {
        required,
        integer
      }
    },
  },

  created: function() {
    this.$dayjs.locale('fr')
    this.getConnectedColalborateur()
  },

  methods: {
    getConnectedColalborateur () {
      this.isProfileLoading = true
      apiUserConnectedService.getConnectedCollaborateur(this.token)
        .then((result) => {
          this.sex = result.data.sex
          this.firstName = result.data.user.first_name
          this.lastName = result.data.user.last_name
          var birthdate = this.$dayjs(result.data.birthdate, 'YYYY-MM-DD')
          this.year = birthdate.format('YYYY')
          this.month = birthdate.format('MM')
          this.day = birthdate.format('DD')
          this.phoneNumber = result.data.phone_number
          this.phoneNumberResult = {
            countryCode: result.data.phone_country_code,
            isValid: true,
            nationalNumber: result.data.phone_number
          }

        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isProfileLoading = false
        })
    },

    updateCollaborateur () {
      this.updateCollaborateurInProcess = true
      this.updateCollaborateurButtonText = "Mise à jour en cours"
      this.updateCollaborateurButtonStyle = 'secondary'
      apiUserConnectedService.updateCollaborateurProfil(
        this.token, this.sex, this.firstName, this.lastName,
        this.birthdateFormat, this.phoneNumberResult.countryCode, this.phoneNumberResult.nationalNumber)
      .then(() => {
        this.profileEdited = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.updateCollaborateurInProcess = false
        this.updateCollaborateurButtonText = "Mettre à jour votre profil"
        this.updateCollaborateurButtonStyle = 'outline-primary'
      })

    },
  }
}
</script>
